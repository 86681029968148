import ErrorComponent from "@cospex/client/components/Error";
import Header from "@cospex/client/components/Header";
import LanguageSelect from "@cospex/client/components/LanguageSelect";
import { useGlobalConfig } from "@cospex/client/hooks/useGlobalConfig";
import { Box, Container } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Outlet } from "react-router-dom";

export default function AppPage() {
  const { languages } = useGlobalConfig();
  return (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
      <Header />
      <Outlet />
      <Container>
        <Box sx={{ my: "3rem" }}>
          <LanguageSelect options={languages} />
        </Box>
      </Container>
    </Sentry.ErrorBoundary>
  );
}
